import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import {
  DataShareService,
  StateService,
  LoaderService,
  BrickBaseService,
  SyncWsBricksService,
  HistoryStackService
} from '../../geoplanner/src/app/core/services';
import { AppHeaderService } from './app-header.service';
import { InitialConfigModel, SystemFlags, Campaign, UiControl, Filter, ProximityProhibitionGroup } from '../../geoplanner/src/app/models';
import { UserModel } from '../../geoplanner/src/app/models/user';
import { SearchService } from '../../geoplanner/src/app/search';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SbModalPopupService } from '../../geoplanner/src/app/core/components/sb-modal-popup';
import { ConfirmationModalComponent } from '../../geoplanner/src/app/result/confirmation-modal/confirmation-modal.component';
import { AppNameEnum, EnvironmentId, ThirdParty } from '../../geoplanner/src/app/core/enum';
import { GLOBAL } from '../../geoplanner/src/app/core/utils/app.constant';
import { ConfigUtils } from '../../geoplanner/src/app/core/utils/configUtils';
import { AuditModel } from '../../geoplanner/src/app/models/auditModel';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuditModalComponent } from '../../geoplanner/src/app/workspace/audit-modal/audit-modal.component';
import { SupportedLanguage } from '../../geoplanner/src/app/models/initial-config.model';
import { environment } from 'environments/environment';
import { GeoMapService } from '../../geoplanner/src/app/geo-map/geo-map.service';
import { HttpParams } from '@angular/common/http';
import { ConcertinaCanvasService } from '../../geoplanner/src/app/core/components/concertina';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('myHeader') myHeader: ElementRef;
  initialConfig: InitialConfigModel;
  userModel: UserModel;
  disabledSearchTab = false;
  disabledWorkSpaceTab = false;
  disabledGeoPlannerTab = false;
  disabledVisualPlannerTab = false;
  resultTabActive = false;
  commercialTabActive = false;
  geoPlannerVisible = true;
  visualPlannerVisible = true;
  auditRecords: AuditModel[] = [];
  auditTooltipText = '';
  private uiControl: UiControl;
  private ww = new Worker('assets/scripts/keep-alive-thread.js');
  private subscriptions: Subscription[] = [];
  enabledPCM = false;
  objectiveMode = false;
  lockedMessage = '';
  forceRerunMessage = '';
  infoMessage = '';
  forceRerun = false;
  queryStringParams: { id: number, action: string } = null;
  selectedEnvironment = '';
  private observer: MutationObserver;
  supportedLanguages: SupportedLanguage[] = [];
  selectedLanguage = '';
  isOnCommercial = false;
  constructor(
    public router: Router,
    private appHeaderService: AppHeaderService,
    private dataShareService: DataShareService,
    private stateService: StateService,
    private loaderService: LoaderService,
    private searchService: SearchService,
    private brickBaseService: BrickBaseService,
    private sbModalPopupService: SbModalPopupService,
    private syncWsBricksService: SyncWsBricksService,
    private historyStackService: HistoryStackService,
    private geoMapService: GeoMapService,
    public concertinaCanvasService: ConcertinaCanvasService
  ) {
  }

  ngAfterViewInit(): void {
    this.observer = new MutationObserver(() => {
      const elementHeight = this.myHeader?.nativeElement?.offsetHeight;
      if (elementHeight) {
        this.appHeaderService.setAppHeaderheight(elementHeight);
      }
    });

    this.observer.observe(this.myHeader.nativeElement, { attributes: true, childList: true, characterData: true });
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationStart) {
        if (Object.keys(event.snapshot.queryParams).length > 0) {
          this.queryStringParams = {
            id: event.snapshot.queryParams.id ? parseInt(event.snapshot.queryParams.id, 10) : null,
            action: event.snapshot.queryParams.action ? event.snapshot.queryParams.action : null
          };
        } else if (window.location.href.split('?').length > 1) {
          const params = window.location.href.split('?')[1];
          const values = new HttpParams({ fromString: params });
          this.queryStringParams = { 'id': 0, action: '' };
          for (const key of values.keys()) {
            this.queryStringParams[key] = values[key] || values.get(key);
          }
        } else {
          this.queryStringParams = null;
        }
        // SM-10284
        if (GLOBAL.BRIC_CAMPAIGN_ID <= 0 && event.snapshot.routeConfig.path !== '' && this.queryStringParams && this.queryStringParams.action === 'clone') {
          const urlParams = new URLSearchParams(window.location.search);
          const marketParam = urlParams.get('market');
          if (marketParam && marketParam.indexOf('gb') !== -1) {
            this.getInitialConfig();
          }
        } else if (GLOBAL.BRIC_CAMPAIGN_ID <= 0 && event.snapshot.routeConfig.path !== '' && this.selectedEnvironment !== '') {
          if (this.queryStringParams) {
            this.router.navigate(['search'], { queryParams: this.queryStringParams });
          }
        }

        if (event.snapshot.url.length > 0 && this.selectedEnvironment !== '') {
          this.isOnCommercial = event.snapshot.url[0].path === 'commercial';
          switch (event.snapshot.url[0].path) {
            case 'workspace':
            case 'result':
            case 'commercial':
              this.setGMRefreshFilter();
              this.setVPRefreshFilter();
              break;
            case 'geoplanner':
              this.setVPRefreshFilter();
              break;
            case 'visualplanner':
              this.setGMRefreshFilter();
              break;
            default:
              break;
          }
        }
      }
    });
    if (!this.dataShareService.getInitialConfig() || !this.stateService.isDataAvailable) {
      this.getInitialConfig();
    } else {
      this.initialConfig = this.dataShareService.getInitialConfig();
      this.userModel = this.dataShareService.getUserModel();
      this.geoPlannerVisible = this.initialConfig.uiControl['enableGeoMapper'];
      this.visualPlannerVisible = this.initialConfig.uiControl['enableVisualPlanner'];
      this.auditTooltipText = this.initialConfig.userBundle['common.notificationmessage'];
      this.dataShareService.setInitialConfig(this.initialConfig);
      this.loadCampaignFromURL();
    }

    this.subscriptions.push(
      this.appHeaderService.resetConfig$.subscribe((data) => {
        if (data && data.getConfig) {
          this.getInitialConfig(data.getBricStructure);
        }
      })
    );

    this.subscriptions.push(this.searchService.loadCampaignSub.subscribe((response) => {
      this.onLoadCampaign(response);
      this.stateService.setSerchAccordian(true);
    }));
    this.subscriptions.push(
      this.dataShareService.activeResultTab.subscribe((isTabActive) => {
        this.resultTabActive = isTabActive;
      })
    );
    this.subscriptions.push(
      this.dataShareService.activeCommercialTab.subscribe((isTabActive) => {
        this.commercialTabActive = isTabActive
      })
    )
    this.subscriptions.push(this.searchService.cloneCampaignSub.subscribe((response) => {
      this.cloneCampaign(response);
      this.stateService.setSerchAccordian(true);
      this.resultTabActive = false;
      this.commercialTabActive = false
      this.disabledGeoPlannerTab = true;
      this.disabledVisualPlannerTab = true;
    }));
    this.subscriptions.push(
      this.dataShareService.showVisualPlannerTab.subscribe((isTabActive) => {
        this.visualPlannerVisible = isTabActive && this.initialConfig.uiControl['enableVisualPlanner'];
      })
    );
    this.subscriptions.push(
      this.dataShareService.showGeoMapperTab.subscribe((isTabActive) => {
        this.geoPlannerVisible = isTabActive && this.initialConfig.uiControl['enableGeoMapper'];
      })
    );
    this.subscriptions.push(
      this.dataShareService.isEnableGeoMapperTab.subscribe((isTabActive) => {
        if (this.initialConfig.uiControl['enableGeoMapper']) {
          this.disabledGeoPlannerTab = !isTabActive;
        } else {
          this.disabledGeoPlannerTab = true;
        }
      })
    );
    this.subscriptions.push(
      this.dataShareService.isEnableWorkspaceTab.subscribe((isTabActive) => {
        this.disabledWorkSpaceTab = !isTabActive;
      })
    );
    this.subscriptions.push(
      this.dataShareService.isEnableVisualPlannerTab.subscribe((isTabActive) => {
        if (this.initialConfig.uiControl['enableVisualPlanner']) {
          this.disabledVisualPlannerTab = !isTabActive;
        } else {
          this.disabledVisualPlannerTab = true;
        }
      })
    );

    this.subscriptions.push(
      this.appHeaderService.enabledPCM$.subscribe((isEnabled: boolean) => {
        this.enabledPCM = isEnabled;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.objectiveMode$.subscribe((isEnabled: boolean) => {
        this.objectiveMode = isEnabled;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.lockedMessage$.subscribe((msg: string) => {
        this.lockedMessage = msg;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.forceRerunMessage$.subscribe((msg: string) => {
        this.forceRerunMessage = msg;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.infoMessage$.subscribe((msg: string) => {
        this.infoMessage = msg;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.forceRerun$.subscribe((msg: boolean) => {
        this.forceRerun = msg;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.auditRecords$.subscribe((auditRecords: AuditModel[]) => {
        this.auditRecords = auditRecords;
      })
    );

    this.subscriptions.push(
      this.appHeaderService.selectedEnvironment$.subscribe(async (newEnv: string) => {
        this.selectedEnvironment = newEnv;
        const urlParams = new URLSearchParams(window.location.search);
        const marketParam = urlParams.get('market');
        if (newEnv && newEnv.length) {
          await this.getInitialConfig(true);
          if (marketParam) {
            this.queryStringParams = {
              'id': parseInt(urlParams.get('id'), 10),
              'action': urlParams.get('action')
            };
            this.loadCampaignFromURL();
          } else {
            this.router.navigate(['search']);
          }

          if (this.uiControl.pendoEnabled) {
            if (window['pendo']) {
              ConfigUtils.updatePendoOptions(this.initialConfig.userData.userName, this.selectedEnvironment, this.selectedLanguage);
            } else {
              ConfigUtils.loadPendo(this.initialConfig.userData.userName, this.selectedEnvironment, this.uiControl['pendoSubscriptionKey'], this.initialConfig.userData.locale);
            }
          }

        } else {
          this.router.navigate(['']);
        }
      })
    );
    GLOBAL.ACCESS_TOKEN = this.appHeaderService.getCookie('token');
    const configURL = this.appHeaderService.getCookie('configURL');
    if (GLOBAL.ACCESS_TOKEN && configURL) {
      this.appHeaderService.getLandingConfig(configURL).subscribe((config: any) => {
        const selectedMarket = this.appHeaderService.getCookie('market');
        let isMarketShifted = false;
        let defaultURL = '';
        let matchFound = false;
        if (config && config.automation) {
          for (const key in config.automation) {
            if (Object.prototype.hasOwnProperty.call(config.automation, key)) {
              const env = config.automation[key];
              if (env.markets.findIndex(market => market === selectedMarket) > -1) {
                isMarketShifted = key !== window.location.origin;
                matchFound = true;
              }
              if (env.default) {
                defaultURL = key;
              }
            }
          }
        }
        if (!isMarketShifted && !matchFound) {
          // selected market should be on default URL now
          isMarketShifted = defaultURL !== window.location.origin;
        }
        const availableMarkets = this.appHeaderService.getCookie(window.location.origin);
        if (!availableMarkets || JSON.parse(availableMarkets).indexOf(selectedMarket) === -1 || isMarketShifted) {
          window.location.replace(environment.landingAppUrl);
          return;
        }
        if (!GLOBAL.LOCAL_JSON) {
          const api = this.appHeaderService.getCookie('api');
          GLOBAL.SERVICE_BASE_URL = `${api}/Service/`;
          GLOBAL.BRIC_CONFIG_URL = `${GLOBAL.SERVICE_BASE_URL}../Service/brics/config`;
        }
        const marketList = this.appHeaderService.getCookie('marketList');
        this.appHeaderService.changeEnvironment(selectedMarket, marketList ? JSON.parse(marketList) : []);
      });
    } else {
      window.location.replace(environment.landingAppUrl);
    }
  }

  /**
   *  Method to open a modal for Audit Records
   */
  showAuditMessages(): void {
    const resolveObject = {
      AuditRecords: this.auditRecords,
      SelectedTitle: 'Campaign Audit'
    };
    const modalOptions: NgbModalOptions = {
      windowClass: 'modal-backdrop Incharge'
    };
    this.sbModalPopupService.open(AuditModalComponent, resolveObject, modalOptions).result.then(() => {
    }, () => { console.log('Rejected'); });
  }

  /**
   * @description - set VP refresh flag to true so on vp load reload the filter data
   * @author Alkesh Shah
   * @memberof AppHeaderComponent
   */
  setVPRefreshFilter(): void {
    const vpCommWithOtherPage = this.stateService.getVPObject('commWithOtherPage');
    vpCommWithOtherPage.refreshFilter = true;
    this.stateService.setVPObject('commWithOtherPage', vpCommWithOtherPage);
  }

  /**
   * @description - set GM refresh flag to true so on vp load reload the filter data
   * @author Alkesh Shah
   * @memberof AppHeaderComponent
   */
  setGMRefreshFilter(): void {
    const gmCommWithOtherPage = this.stateService.getGeoMapperObject('commWithOtherPage');
    gmCommWithOtherPage.refreshFilter = true;
    this.stateService.setGeoMapperObject('commWithOtherPage', gmCommWithOtherPage);
  }

  async getInitialConfig(makeBricsMasterCall = false, selectedLocale = null) {
    if (this.selectedEnvironment === '') {
      return;
    }
    return new Promise(async (resolve) => {
      await this.searchService.getInitialConfig(selectedLocale).subscribe(async (response: any) => {
        if (response.status === 'OK') {
          if (response.data && Array.isArray(response.data.productCategoryGroup)) {
            response.data.productCategoryGroup.forEach((pcg) => {
              if (pcg && Array.isArray(pcg.productCategory)) {
                pcg.productCategory = _.orderBy(pcg.productCategory, pc => pc.productCategoryName && pc.productCategoryName.toUpperCase(), 'asc');
              }
            });
          }
          this.initialConfig = response.data;
          this.supportedLanguages = [];
          if (this.initialConfig.uiControl.executeButtonGmVp) {
            this.geoMapService.allowProcessVp(false);
            this.geoMapService.allowProcessGm(false);
          }
          if (this.initialConfig.uiControl.supportedLanguages) {
            for (const key in this.initialConfig.uiControl.supportedLanguages) {
              if (this.initialConfig.uiControl.supportedLanguages.hasOwnProperty(key)) {
                const language = this.initialConfig.uiControl.supportedLanguages[key];
                this.supportedLanguages.push({
                  language,
                  locale: key
                });
              }
            }
          }
          this.selectedLanguage = this.initialConfig.userData.locale;
          this.dataShareService.setInitialConfig(response.data);
          // SM-7846 Here we set new WorkspaceObject and PCMObject after getting new initConfig. Already we have set this in resetCampaign() in search.service.ts but,here need new one when getting new initconfig.
          if (!selectedLocale) {
            this.stateService.setWorkspaceObject('filter', this.stateService.getFreshFilterObj());
            this.stateService.setPCMObject('filter', this.stateService.getFreshFilterObj());
          }
          this.userModel = this.dataShareService.getUserModel();
          this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
          this.geoPlannerVisible = this.initialConfig.uiControl['enableGeoMapper'];
          this.visualPlannerVisible = this.initialConfig.uiControl['enableVisualPlanner'];
          this.auditTooltipText = this.initialConfig.userBundle['common.notificationmessage'];
          if (makeBricsMasterCall || !this.dataShareService.getBricsMasterData()) {
            await this.searchService.getBricsMasterData(this.dataShareService.getServiceCallUrlByKey('BRIC_MASTER_URL'), selectedLocale)
              .subscribe((bricsResponse: any) => {
                this.dataShareService.setBricsMasterData(bricsResponse);
                this.brickBaseService.setBricProperties(bricsResponse);
                resolve(true);
              });
          }
          // SM-10284
          // https://automation.uat.develop.farm/#/search?market=market:jcdqa:gb&id=816892&action=clone
          const environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
          if (this.queryStringParams && this.queryStringParams.action && this.queryStringParams.action === 'clone' && environmentId === EnvironmentId.UK) {
            this.cloneCampaign({ bricsCampaignId: +this.queryStringParams.id });
          }
          if (!selectedLocale) {
            if (!this.uiControl['baiduMapEnabled'] && !window['google']) {
              await ConfigUtils.addJavaScriptLibraryDynamicaly(
                `https://maps.googleapis.com/maps/api/js?key=${this.uiControl['mapsAPIClientId']}&libraries=places,drawing`
              )
                .then((successMessage) => {
                  if (successMessage === 'loaded') {
                    GLOBAL.isOnline = window['google'] ? true : false;
                  }
                  this.loadCampaignFromURL();
                }, () => {
                  this.loadCampaignFromURL();
                });
            } else {
              this.loadCampaignFromURL();
              await ConfigUtils.addJavaScriptLibraryDynamicaly(`//api.map.baidu.com/api?v=2.0&ak=${this.uiControl.baiduMapApiKey}&callback=baidu_map_init&s=${(location.protocol === 'https:' ? 1 : 0)}`)
                .then((successMessage) => {
                  if (successMessage === 'loaded') {
                    GLOBAL.isOnline = window['BMap'] ? true : false;
                  }
                }, (error) => {
                  console.error(error);
                });
            }

            if (this.uiControl['googleAnalyticsEnabled']) {
              GLOBAL.googleAnalyticsEnabled = this.uiControl['googleAnalyticsEnabled'];
              const key = this.uiControl['googleAnalyticsKey'] ? this.uiControl['googleAnalyticsKey'] : 'UA-117126225-1';
              ConfigUtils.loadGoogleAnalytics(key);
            }

            if (this.uiControl.pendoEnabled) {
              if (window['pendo']) {
                ConfigUtils.updatePendoOptions(this.initialConfig.userData.userName, this.selectedEnvironment, this.selectedLanguage);
              } else {
                ConfigUtils.loadPendo(this.initialConfig.userData.userName, this.selectedEnvironment, this.uiControl['pendoSubscriptionKey'], this.initialConfig.userData.locale);
              }
            }

            this.startKeepAlive();
          }
        }

        // SM-11965 Added below code to integrate DataDog with Automation
        datadogRum.init({
          applicationId: this.initialConfig.uiControl['dataDogApplicationId'],
          clientToken: this.initialConfig.uiControl['dataDogClientToken'],
          // `site` refers to the Datadog site parameter of your organization
          // see https://docs.datadoghq.com/getting_started/site/
          site: 'datadoghq.eu',
          service: 'automation',
          env: this.getEnvironment(),
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
        datadogRum.setUser({
          name: this.initialConfig.userData.fullName,
          email: this.initialConfig.userData.emailAddress
        });
        datadogRum.startView({
          service: 'automation',
          version: GLOBAL.frontEndVersion
        });
        datadogRum.setGlobalContextProperty('publisher_external_id', this.selectedEnvironment);

        // --- DataDog ---
      }, (error: Error) => {
        console.log(error);
      });
    });
  }

  getEnvironment() {
    let env = "";
    if (environment.landingAppUrl.includes('dev')) {
      env = "DEV"
    } else if (environment.landingAppUrl.includes('uat')) {
      env = "UAT"
    } else if (environment.landingAppUrl.includes('prd')) {
      env = "PRD"
    }
    return env;
  }

  /**
   * @description Format data for proximity prohibition from campaign load response
   * @author  Nishit Parekh, Amit Mahida
   * @param {*} proximityProhibition
   * @returns {ProximityProhibition[]}
   * @memberof AppHeaderComponent
   */
  populateProximityProhibition(proximityProhibition): ProximityProhibitionGroup[] {
    const proximityProhibitionGroup: ProximityProhibitionGroup[] = this.initialConfig.proximityProhibitionGroup;
    if (this.initialConfig.proximityProhibitionGroup) {
      proximityProhibitionGroup.forEach((groupObj) => {
        groupObj.proximityProhibitionType.forEach((prohibitionTypeObj) => {
          proximityProhibition.forEach((obj) => {
            if (prohibitionTypeObj.prohibitionTypeId === obj.prohibitionTypeId) {
              prohibitionTypeObj.distance = obj.distance;
              prohibitionTypeObj.distanceType = 0;
              prohibitionTypeObj.olddistanceType = 0;
              prohibitionTypeObj.distancetypedisplay = 0;
              prohibitionTypeObj.distancevaluedisplay = obj.distance;
            }
          });
        });
      });
    }
    return _.isUndefined(proximityProhibitionGroup) ? [] : proximityProhibitionGroup;
  }

  /**
   * @author Vijay Sutaria
   * @description This method will populate specialist and agency from thirdparty values if available
   * @param thiryParty
   */
  populateThirdParty(campaign: Campaign) {
    const thirdParty = campaign.thirdParty;

    if (thirdParty) {
      if (thirdParty[ThirdParty.specialist]) {
        campaign.specialist = {
          specialistId: thirdParty[ThirdParty.specialist].thirdPartyId,
          specialistName: thirdParty[ThirdParty.specialist].thirdPartyName,
          invoiceStatusCode: thirdParty[ThirdParty.specialist].invoiceStatusCode
        };
      }
      if (thirdParty[ThirdParty.agency]) {
        campaign.agency = {
          agencyId: thirdParty[ThirdParty.agency].thirdPartyId,
          agencyName: thirdParty[ThirdParty.agency].thirdPartyName,
          invoiceStatusCode: thirdParty[ThirdParty.agency].invoiceStatusCode
        };
      }
    }
    // SM-4177, case when we load campaign
    campaign.excludeSpecialistBlacklist = campaign.specialist && campaign.specialist.specialistId > 0 ? campaign.excludeSpecialistBlacklist : true;
    campaign.excludeAgencyBlacklist = campaign.agency && campaign.agency.agencyId > 0 ? campaign.excludeAgencyBlacklist : true;
    return campaign;
  }

  /**
   * @description Sends loaded campaign id to server and fetches its details
   * @author Amit Mahida
   * @param {*} data
   * @memberof SearchComponent
   */
  onLoadCampaign(data: any): void {
    const params: any = {
      id: data.requestParams.id
    };
    if (data.requestParams.action === 0) {
      params.action = 'loadSearchedCampaign';
    } else if (data.requestParams.action === 1) {
      params.action = 'loadBookedCampaign';
    } else if (data.requestParams.action === 2) {
      params.action = 'loadBookingCampaignRequest';
    } else {
      params.action = 'loadApiRequest';
    }
    const loadCampaignUrl = this.dataShareService.getServiceCallUrlByKey('LOAD_BRICS_CAMPAIGN');
    this.subscriptions.push(this.searchService.loadCampaign(params, loadCampaignUrl).subscribe((response) => {
      if (response.status === 'OK') {
        const isProductAvailable = this.isProductCatalogueAvailable(response);
        if (params.action = 'loadBookedCampaign'){
          let price:any[] = response.data.campaignSummary.map(summary => summary.finalPrice?.replace(/\.\d+/g, ''));
          this.dataShareService.loadedFinalPrice = price;
          this.stateService.getWorkspaceFilterObj().updateLookupData(response.data.bricsData, response);
        }
        if (!isProductAvailable) {
          this.campaignLoadFailure(this.initialConfig.userBundle['common.error.productAccessDenied'] || 'User don’t have rights to product catalogue which are associated to campaign', true);
          return;
        }
        this.loaderService.show();
        let campaign: Campaign = Object.assign(new Campaign(), response.data.campaignHeader);
        campaign.proximityProhibition = this.populateProximityProhibition(campaign.proximityProhibition);
        campaign = this.populateThirdParty(campaign);

        this.stateService.setInitialStateCampaign(campaign);
        this.stateService.setWorkspaceObject('columnLineNumber', response.data.columnLineNumber);
        this.stateService.setWorkspaceObject('workspaceUIControl', response.data.uiControl);
        this.stateService.setColumnHelper(response.data.columnHelper);
        this.dataShareService.setLoadedCampaignFlag(true);
        this.historyStackService.resetHistoryStack();
        SystemFlags.isLoadedCampaign = true;
        this.stateService.resetExceptSearch();
        GLOBAL.BRIC_CAMPAIGN_ID = response.data.bricsCampaignId;
        SystemFlags.isPPCampaign = response.data.isPPCampaign;
        SystemFlags.isAPICampaign = response.data.isAPICampaign;
        SystemFlags.getBasketDataForGP = false;
        SystemFlags.getBasketDataForVP = false;
        SystemFlags.readOnly = response.data.readOnly || false;
        SystemFlags.readOnlyWorkspace = response.data.readOnlyWorkspace || false;
        SystemFlags.splitable = response.data.splitable || false;
        SystemFlags.splitted = false;
        SystemFlags.enableSearchFieldsForExternalUser = response.data.editable || false;
        SystemFlags.campaignLoadError = response.data.campaignLoadError || '';
        SystemFlags.forcefullyCallProcessBrics = false;
        if (response.data.lockReason) {
          SystemFlags.lockReason = response.data.lockReason;
        }
        // clear process request for GM/VP
        this.geoMapService.resetProcessRequest();
        if (this.uiControl.executeButtonGmVp) {
          this.geoMapService.allowProcessGm(false);
          this.geoMapService.allowProcessVp(false);
        }

        // check lock message in response and show in header
        if (response.data.lockedMessage) {
          this.appHeaderService.changeLockedMessage(response.data.lockedMessage);
        } else {
          this.appHeaderService.changeLockedMessage(null);
        }

        // check forceRerun status in response
        if (response.data.forceRerun) {
          this.appHeaderService.changeforceRerunStatus(response.data.forceRerun);
        } else {
          this.appHeaderService.changeforceRerunStatus(false);
        }

        // check mandatory message in response and show in header
        if (response.data.forceRerunMessage) {
          this.appHeaderService.changeforceRerunMessage(response.data.forceRerunMessage);
        } else {
          this.appHeaderService.changeforceRerunMessage(null);
        }

        // check info message in response and show in header
        if (response.data.infoMessage) {
          this.appHeaderService.changeInfoMessage(response.data.infoMessage);
        } else {
          this.appHeaderService.changeInfoMessage(null);
        }

        if (response.data.auditRecords) {
          campaign.auditRecords = response.data.auditRecords;
          this.appHeaderService.setAuditRecords(response.data.auditRecords);
        } else {
          this.appHeaderService.setAuditRecords([]);
        }

        let bricsData = response.data.bricsData;
        if (bricsData != null || bricsData !== undefined) {
          bricsData = _.sortBy(bricsData, 'row');
          this.syncWsBricksService.bricksDataCalculations(bricsData, response.data); // and condition for issue 229 and 230
          this.stateService.setCampaignSummary([]);
          this.stateService.setLoadedCampaignSummary(_.cloneDeep(response.data.campaignSummary));
          if (this.stateService.getWorkspaceFilterObj().isMandatoryBricksAvailable() && response.data.campaignSummary.length) {
            this.dataShareService.activateResultTab(true);
            this.dataShareService.activateCommercialTab(true)
            this.dataShareService.enableGeoMapperTab(true);
            this.dataShareService.enableVisualPlannerTab(true);
          } else {
            this.dataShareService.activateResultTab(false);
            this.dataShareService.activateCommercialTab(false);
          }
          const campaignLoad = {
            isLoadData: true,
            isResetCampaign: false
          };
          this.stateService.setGeoMapperObject('campaignLoad', campaignLoad);
          this.stateService.setVPObject('campaignLoad', campaignLoad);
        }
        // SM-7952
        this.stateService.setCampaign(campaign);

        // SM-5217 - Alkesh - Tolerance limit in load campaign case
        const filterObj: Filter = this.stateService.getWorkspaceFilterObj();
        filterObj.populateToleranceLimitCellAdd();
        filterObj.campaignParameters = response.data.campaignParameters;
        this.stateService.setWorkspaceObject('filter', filterObj);

        if (data.requestParams.action === 2) { // 'loadBookingCampaignRequest'
          if (this.userModel.commercialTabAccess.tabAccess) {
            this.router.navigate(['/commercial']);
          } else {
            this.router.navigate(['/result']);
          }
        }
        if (this.queryStringParams) {
          this.router.navigate([`/${this.queryStringParams.action}`]);
        }
        this.searchService.updateCampaignDetails(campaign);
        this.loaderService.hide();
      } else {
        console.log('load failed!');
        this.loaderService.hide();
        this.campaignLoadFailure(response.message);
      }
    }, (error) => {
      console.error(error);
    }));
  }

  loadCampaignFromURL() {
    if (this.queryStringParams && this.queryStringParams.action && this.queryStringParams.action === 'clone') {
      return; // preventing loadBookedCampaign action if there is clone action
    } else if (this.queryStringParams && this.queryStringParams.id) {
      const data = {
        requestParams: {
          id: this.queryStringParams.id,
          action: 1 // loadBookedCampaign
        }
      };
      this.onLoadCampaign(data);
    }
  }

  /**
   * To start keep alive service
   * @param {any} data Data for keep alive service
   * @memberof DashboardComponent
   */
  startKeepAlive() {
    if (window['Worker']) {
      const inputForWebWorker = {
        MAX_INACTIVE_INTERVAL: GLOBAL.MAX_INACTIVE_INTERVAL,
        BosSessionId: GLOBAL.BOS_SESSIONID,
        token: GLOBAL.ACCESS_TOKEN,
        KEEP_ALIVE_URL: this.dataShareService.getServiceCallUrlByKey('KEEP_ALIVE_URL'),
        HTTP_METHOD: GLOBAL.HTTP_METHOD,
        BASE_PATH: GLOBAL.SERVICE_BASE_URL
      };
      this.ww.postMessage(inputForWebWorker);
      this.ww.onmessage = function (e) {

      };
    } else {
      console.error('Threading not possible with this browser!');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }

  /**
   * @description Handles campaign load failure
   * @author Amit Mahida, Shivani Patel
   * @param {string} message
   * @memberof AppHeaderComponent
   */
  campaignLoadFailure(message: string, resetCall = false): void {
    this.sbModalPopupService.open(ConfirmationModalComponent, {
      color: 'firebrick',
      SelectedValue: message,
      SelectedTitle: this.initialConfig.userBundle['search.campaign.loadFailure.title'],
      confirmation: true,
    }).result.then(() => {
      this.searchService.resetCampaign(resetCall);
    }, (reason) => {
      console.log(reason);
    });
  }

  onLogoClick() {
    if (this.enabledPCM) {
      this.appHeaderService.changePCMMode(false);
    }
    this.router.navigate(['/search']);
  }

  backToWorkspace() {
    this.appHeaderService.backToWorkspace.next(true);
  }

  async updateLanguage() {
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      this.concertinaCanvasService.initThisService();
    }
    await this.getInitialConfig(true, this.selectedLanguage);
    const workspaceFilter = this.stateService.getWorkspaceFilterObj();
    if (workspaceFilter.rows && workspaceFilter.rows.length) {
      const newFilter = workspaceFilter.refresh();
      this.stateService.setWorkspaceObject('filter', this.stateService.clone(newFilter));
    }
    const pcmFilter = this.stateService.getPCMFilterObj();
    if (pcmFilter.rows && pcmFilter.rows.length) {
      const newFilter = pcmFilter.refresh();
      this.stateService.setPCMObject('filter', this.stateService.clone(newFilter));
    }

    if (this.uiControl.pendoEnabled) {
      if (window['pendo']) {
        ConfigUtils.updatePendoOptions(this.initialConfig.userData.userName, this.selectedEnvironment, this.selectedLanguage);
      } else {
        ConfigUtils.loadPendo(this.initialConfig.userData.userName, this.selectedEnvironment, this.uiControl['pendoSubscriptionKey'], this.initialConfig.userData.locale);
      }
    }

    this.dataShareService.languageChanged(true);
  }

  /**
 * @description This method setting up the campaign and brics structure to clone the existing campaign.
 * @author Dhaval Patel
 * @param response Response comes from clone campaign API
 * @memberof AppHeaderComponent
 */
  cloneCampaign(data: any) {
    this.searchService.resetCampaign(false, false, true);
    const params: any = {
      id: data.bricsCampaignId,
      action: 'loadCampaignWithoutCampaignLock'
    };
    this.loaderService.show();
    const loadCampaignUrl = this.dataShareService.getServiceCallUrlByKey('LOAD_BRICS_CAMPAIGN');
    this.searchService.loadCampaign(params, loadCampaignUrl).subscribe((response) => {
      let campaign: Campaign = Object.assign(new Campaign(), response.data.campaignHeader);
      campaign.bookingStatusId = undefined;
      campaign.campaignTypeId = -1;
      campaign.campaignTypeGroupId = -1;
      campaign.proximityProhibition = this.populateProximityProhibition(campaign.proximityProhibition);
      campaign = this.populateThirdParty(campaign);

      this.stateService.setInitialStateCampaign(campaign);
      SystemFlags.isCloneCampaign = true;
      SystemFlags.isRangeSelectionMandatory = true;
      this.dataShareService.enableGeoMapperTab(false);
      this.dataShareService.enableVisualPlannerTab(false);
      let bricsData = response.data.bricsData;
      if (bricsData != null || bricsData !== undefined) {
        bricsData = _.sortBy(bricsData, 'row');
        this.syncWsBricksService.bricksDataCalculations(bricsData, response.data); // and condition for issue 229 and 230
        this.stateService.setCampaignSummary([]);
      }
      this.stateService.setCampaign(campaign);
      const filterObj: Filter = this.stateService.getWorkspaceFilterObj();
      this.stateService.setWorkspaceObject('filter', filterObj);
      this.searchService.updateCampaignDetails(campaign);
      this.loaderService.hide();
    }, () => {
      this.loaderService.hide();
    });
  }

  trackByLocale(index, lang) {
    return lang?.locale;
  }

  /**
   * This is responsible to check products are available in product catalogue holder
   * @param response response of load campaign API
   * @returns is product catalogue avaiable or not (Boolean)
   */
  isProductCatalogueAvailable(response) {
    let available = true;
    if (response.data && response.data.bricsData && response.data.bricsData.length) {
      for (const row of response.data.bricsData) {
        if (row.hasOwnProperty('selectionProductCatalogue') && row.selectionProductCatalogue.productCatalogueId) {
          const productObjectIndex = this.initialConfig.productCatalogueHolder.productCatalogue.findIndex((product) => {
            return product.idProductCatalogue === row.selectionProductCatalogue.productCatalogueId;
          });
          if (productObjectIndex === -1) {
            available = false;
          }
        }
      }
    }
    return available;
  }
}
